import {isEditor, isMobile} from '../../../../../commons/selectors/environment'
import {isButtonLayout} from '../../selectors/single-settings'
import {getListLayout, isEmptyStateNotificationVisible, isNarrow} from '../../selectors/list-settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {getComponentConfig, isSingleEventWidget} from '../../selectors/settings'
import {Layout as Presentation} from './layout'
import {LayoutStateProps, LayoutOwnProps} from './interfaces'

const mapRuntime = ({state, host}: AppProps): LayoutStateProps => ({
  listLayout: getListLayout(getComponentConfig(state)),
  singleEventWidget: isSingleEventWidget(getComponentConfig(state)),
  emptyStateVisible: isEmptyStateNotificationVisible(state),
  mobile: isMobile(state),
  buttonOnly: isButtonLayout(state.component),
  editor: isEditor(state),
  narrowLayout: isNarrow(state, host.dimensions),
})

export const Layout = connect<LayoutOwnProps, LayoutStateProps>(mapRuntime)(Presentation)
export * from './interfaces'
