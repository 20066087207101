import {Component} from 'react'
import {AppLoadedProps} from '.'

export class AppLoaded extends Component<AppLoadedProps> {
  componentDidMount() {
    if (this.props.viewMode === 'Site') {
      this.props.widgetLoaded(this.props.isHomePage, this.props.host.dimensions)
    }
  }

  render() {
    return null
  }
}
